import {useEffect, useState} from "react";

const Background = () => {
    const isImage = (process.env.REACT_APP_BACKGROUND_IMAGE === 'true');
    const blurred = (process.env.REACT_APP_BACKGROUND_BLURRED === 'true');

    const [backgroundFile, setBackgroundFile] = useState<string | undefined>(undefined);

    const loadBackground = (backgroundFileName: string) => {
        import(`../assets/${backgroundFileName}`).then(image => {
            setBackgroundFile(image.default);
        });
    }

    useEffect(() => {
        const backgroundFileName = process.env.REACT_APP_BACKGROUND_FILE;
        if (backgroundFileName) loadBackground(backgroundFileName)
    }, []);

    if (isImage) {
        return (
            <div
                className={`${blurred ? 'blur scale-105' : ''} absolute top-0 left-0 w-full h-full bg-cover bg-center -z-10`}
                style={{backgroundImage: `url(${backgroundFile})`}}/>
        )
    }

    return <video
        className={`${blurred ? 'blur scale-105' : ''} absolute top-0 left-0 w-full h-full object-cover -z-10`} autoPlay
        muted loop src={backgroundFile}/>
}

export default Background;
