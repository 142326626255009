import {FC} from "react";
import FacebookLogo from '../assets/facebook_logo.webp'

interface Props {
    onClick: () => void;
    logout: any;
    token?: string;
}

const FacebookButton: FC<Props> = (props) => {
    const handleClick = () => {
        if (props.token) {
            props.logout();
        } else {
            props.onClick();
        }
    }
    return <button className="hover:bg-black/60 text-white font-bold rounded w-8 h-8"
                   onClick={() => handleClick()}><img alt="facebook logo" className="w-full h-full" src={FacebookLogo}/></button>
}

export default FacebookButton;
