import React, {FC} from "react";

interface Props {
    className?: string;
    text: string;
    onClick: () => void;
}

const Button: FC<Props> = ({className, text, onClick}) => {
    return <button onClick={onClick} className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${className}`}>
        {text}
    </button>
}

export default Button
