import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

interface Props {
    date: Date;
    className?: string;
}

type Countdown = {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

const CountdownComponent: FC<Props> = ({date, className}) => {
    const {t} = useTranslation();
    const calculateTimeLeft = (): Countdown => {
        if (date == null) return {days: 0, hours: 0, minutes: 0, seconds: 0};
        const difference = date.getTime() - Date.now();
        if (difference <= 0) {
            return {days: 0, hours: 0, minutes: 0, seconds: 0};
        }
        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };

    }

    const [countdown, setCountdown] = useState<Countdown>(calculateTimeLeft());

    useEffect(() => {
        const interval = setInterval(() => {
            const timeLeft = calculateTimeLeft();
            setCountdown(prev => timeLeft);
        }, 1000);
        return () => clearInterval(interval);
    }, [date]);

    return <div className={className}>
        <div className="p-4 md:p-8 rounded-lg">
            <div className="flex items-center justify-center">
                <div className="flex items-center justify-center text-white rounded-full w-12 h-12 mr-4">
                    <p className="text-2xl md:text-3xl font-bold">{countdown.days}</p>
                </div>
                <div className="flex items-center justify-center text-white rounded-full w-12 h-12 mr-4">
                    <p className="text-2xl md:text-3xl font-bold">{countdown.hours}</p>
                </div>
                <div className="flex items-center justify-center text-white rounded-full w-12 h-12 mr-4">
                    <p className="text-2xl md:text-3xl font-bold">{countdown.minutes}</p>
                </div>
                <div className="flex items-center justify-center text-white rounded-full w-12 h-12">
                    <p className="text-2xl md:text-3xl font-bold">{countdown.seconds}</p>
                </div>
            </div>
            <div className="flex items-center justify-center mt-2 md:mt-6">
                <p className="text-gray-100 text-sm">{t('days')}</p>
                <p className="text-gray-100 text-sm ml-3 md:ml-6">{t('hours')}</p>
                <p className="text-gray-100 text-sm ml-3 md:ml-6">{t('minutes')}</p>
                <p className="text-gray-100 text-sm ml-3 md:ml-6">{t('seconds')}</p>
            </div>
        </div>
    </div>
}

export default CountdownComponent;
