import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {CredentialResponse, GoogleLogin, googleLogout} from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import FacebookLogin, {FacebookLoginClient} from "@greatsumini/react-facebook-login";
import {
    checkIfSubscribedToNewsletter,
    subscribeToNewsletterRequest,
    unsubscribeFromNewsletterRequest
} from "./utils/subscribeToNewsletter";
import CountdownComponent from "./components/countdownComponent";
import Background from "./components/background";
import Button from "./components/button";
import FacebookButton from "./components/facebookButton";
import {getPushNotification} from "./utils/getPushNotification";

function App() {
    const appName = process.env.REACT_APP_APP_NAME || '';
    const releaseDate = process.env.REACT_APP_RELEASE_DATE || '';
    const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID || '';

    const {t} = useTranslation();
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [hasSubscribed, setHasSubscribed] = useState<boolean>(false);

    const onGoogleLoginSuccess = (response: CredentialResponse) => {
        const decoded: any = jwtDecode(response.credential!);
        setEmail(decoded.email)
        checkIfSubscribedToNewsletter(decoded.email).then(setHasSubscribed)
    }

    const onGoogleLoginClick = () => {
        FacebookLoginClient.logout(() => {
            setEmail(undefined)
        });
    }

    const onFacebookProfileSuccess = (response: any) => {
        setEmail(response.email)
        checkIfSubscribedToNewsletter(response.email).then(setHasSubscribed)
    }

    const subscribeToNewsletter = () => {
        if (!email) return
        subscribeToNewsletterRequest(email);
        checkIfSubscribedToNewsletter(email).then(setHasSubscribed)
    }

    const unsubscribeFromNewsletter = () => {
        if (!email || !hasSubscribed) return
        unsubscribeFromNewsletterRequest(email).then(() => setHasSubscribed(false))
    }

    useEffect(() => {
        getPushNotification()
    }, [])

    return (
        <div className="w-full h-screen flex flex-col items-center overflow-hidden relative">
            <Background/>
            <h1 className="text-5xl text-white mt-4 drop-shadow-lg">{appName ? appName : ''}</h1>
            <h2 className="text-4xl text-white mt-24 drop-shadow-lg">{t('launch')}</h2>
            <CountdownComponent className="w-2/3 mt-4" date={new Date(releaseDate)}/>
            <span className="flex-1" />
            <div className="flex flex-col w-2/3 md:w-60 gap-2 mb-16">
                {(email && !hasSubscribed) && <Button text={t('subscribeToNewsletter')} onClick={subscribeToNewsletter}/>}
                {email && hasSubscribed && <Button text={t('unsubscribeFromNewsletter')} onClick={unsubscribeFromNewsletter} />}
                <div className="flex w-full justify-evenly items-center mt-4">
                    <GoogleLogin  type="icon" width="100%" click_listener={onGoogleLoginClick}
                                 onSuccess={onGoogleLoginSuccess}/>
                    <FacebookLogin className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                   appId={facebookAppId}
                                   render={({onClick, logout}) => <FacebookButton onClick={onClick!} logout={logout}/>
                                   } onSuccess={() => {
                        googleLogout()
                    }} onFail={() => {
                        googleLogout()
                    }} onProfileSuccess={onFacebookProfileSuccess}
                    />
                </div>
            </div>
        </div>
    );
}

export default App;
