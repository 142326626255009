import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import XHR from 'i18next-http-backend';

import english from './translations/en';
import polish from './translations/pl';

const resources = {
    en: {
        translation: english
    },
    pl: {
        translation: polish
    }
}

const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng',
}

i18n
    .use(XHR)
    .use(initReactI18next)
    .use(detector)
    .init({
        resources,
        detection: options,
        fallbackLng: ["en", "pl"],
    })
