export const subscribeToNewsletterRequest = async (email: string) => {
    const baseUrl = process.env.REACT_APP_BACKEND_URL
    const url = `${baseUrl}/api/subscribe/`;
    const service = process.env.REACT_APP_APP_NAME;
    const data = {
        "email": email,
        "service": service
    }
    return (await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }));
}

export const unsubscribeFromNewsletterRequest = async (email: string) => {
    const baseUrl = process.env.REACT_APP_BACKEND_URL
    const url = `${baseUrl}/api/unsubscribe/`;
    return (await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({email, service: process.env.REACT_APP_APP_NAME})
    }));
}

export const checkIfSubscribedToNewsletter = async (email: string): Promise<boolean> => {
    const baseUrl = process.env.REACT_APP_BACKEND_URL
    const url = `${baseUrl}/api/check-newsletter-subscription/`;
    return (await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({email, service: process.env.REACT_APP_APP_NAME})
    })).status === 200;
}
